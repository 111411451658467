<template>
  <b-sidebar
    v-model="isOpen"
    type="is-white"
    :fullheight="true"
    :overlay="true"
    :right="true"
    mobile="fullwidth"
    :can-cancel="['escape']"
  >
    <div :class="[$style.wrapper, additionalClass]">
      <div :class="$style.header">
        <div :class="$style.header__content">
          <slot name="header">
            <span />
          </slot>
        </div>
        <button
          type="button"
          :class="$style.close_btn"
          class="button is-ghost modal-close-btn has-text-dark is-shadowless"
          style="min-width: auto"
          @click="close"
        >
          <font-awesome-icon
            :icon="['fal', 'xmark']"
          />
        </button>
      </div>
      <div :class="$style.body">
        <slot />
      </div>
    </div>
  </b-sidebar>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useSidePanelStore } from '@/stores/index.js';

export default {
  name: 'SidePanel',
  props: {
    name: {
      type: String,
      required: true,
    },
    isInherit: {
      type: Boolean,
      default: false,
    },
    additionalClass: {
      type: String,
      default: '',
    },
    customCloseHandler: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      isPrevious: false,
    };
  },
  computed: {
    ...mapState(useSidePanelStore, ['currentPanel']),

    isOpen: {
      get() { return this.name === this.currentPanel; },
      set(value) {
        if (!value) {
          this.closePanel();
          return;
        }
        this.setPanel(this.value);
      },
    },
  },

  watch: {
    currentPanel(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = null;
      }
    },
  },

  methods: {
    ...mapActions(useSidePanelStore, ['setPanel', 'closePanel']),

    close() {
      if (this.customCloseHandler) {
        this.customCloseHandler();
      }
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" module>
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100vw;
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 10px 5px 10px 20px;
    border-bottom: 1px solid #ededed;

    &__content {
      flex: 1 1 auto;
    }
  }

  .body {
    flex: 1 1 auto;
    padding: 10px 5px 10px 20px;
    overflow: auto;
  }

  .close_btn {
    margin-left: 6px;
    height: auto;
  }

  @media (min-width: 1500px) {
    .wrapper {
      max-width: 1300px;
    }
  }
</style>
