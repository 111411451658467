<template>
  <textarea v-model="value" />
</template>

<script>
import CodeMirror from 'codemirror/lib/codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/scroll/simplescrollbars';

export default {
  name: 'json-viewer',

  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      skipEmit: false,
      codeMirror: null,
    };
  },

  watch: {
    value(value) {
      if (!this.skipEmit) {
        this.codeMirror.setValue(value);
      }

      this.skipEmit = false;
    },
  },

  mounted() {
    this.codeMirror = CodeMirror.fromTextArea(this.$el, {
      mode: { name: 'javascript', json: true },
      indentUnit: 4,
      tabSize: 4,
      indentWithTabs: false,
      lineNumbers: false,
      autofocus: true,
      lineWrapping: true,
      placeholder: this.placeholder,
      autoRefresh: true,
      scrollbarStyle: 'simple',
    });

    this.codeMirror.on('change', (cm) => {
      this.skipEmit = true;

      this.$emit('input', cm.getValue());
    });
  },
};
</script>

<style>
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/scroll/simplescrollbars.css';

.CodeMirror {
    font-weight: 100;
    border: 1px solid #eee;
    height: 300px;
}

.CodeMirror-wrap pre {
    word-break: break-word;
}

.CodeMirror-placeholder {
    color: hsl(0, 0%, 86%) !important;
}
</style>
