<template>
  <div :class="$style.dropdown">
    <b-dropdown
      v-model="currentOptionValue"
      :mobile-modal="false"
      :disabled="loading"
    >
      <template #trigger>
        <div
          :class="$style.dropdown__trigger"
          :title="dropDownTitle"
        >
          <span :class="$style.dropdown__title">{{ dropDownTitle }}</span>

          <span class="icon is-small">
            <font-awesome-icon :icon="['fal', 'chevron-down']" />
          </span>
        </div>
      </template>
      <b-dropdown-item
        :focusable="false"
        paddingless
        custom
      >
        <div :class="$style.dropdown__head">
          <span>Web forms</span>
          <button
            v-if="!isReadyStep"
            type="button"
            title="Manage forms"
            :class="[$style.button, $style.button_small, 'button', 'is-white']"
            @click="goToFormList"
          >
            <font-awesome-icon :icon="['fal', 'gear']" />
          </button>
        </div>
      </b-dropdown-item>

      <b-dropdown-item
        :value="defaultFormOptionName"
        paddingless
      >
        <div
          title="Auto-generated form"
          :class="$style.dropdown__item"
        >
          <span
            v-if="currentOptionValue === defaultFormOptionName"
            :class="['icon is-small', $style['dropdown__marker-current']]"
          >
            <font-awesome-icon :icon="['fal', 'check']" />
          </span>
          <span :class="$style.dropdown__content">
            Auto-generated form
          </span>
        </div>
      </b-dropdown-item>

      <b-dropdown-item
        v-for="{ name, formId } of formItems"
        :key="formId"
        :value="formId"
        paddingless
      >
        <div
          :class="$style.dropdown__item"
          :title="name"
        >
          <span
            v-if="currentOptionValue === formId"
            :class="['icon is-small', $style['dropdown__marker-current']]"
          >
            <font-awesome-icon :icon="['fal', 'check']" />
          </span>
          <span :class="$style.dropdown__content">
            {{ name }}
          </span>
        </div>
      </b-dropdown-item>

      <b-dropdown-item
        :focusable="false"
        :class="$style.dropdown__breakline"
        paddingless
        custom
      />

      <b-dropdown-item
        :value="jsonOptionName"
        aria-role="listitem"
        paddingless
      >
        <div
          title="JSON"
          :class="$style.dropdown__item"
        >
          <span
            v-if="currentOptionValue === jsonOptionName"
            :class="['icon is-small', $style['dropdown__marker-current']]"
          >
            <font-awesome-icon :icon="['fal', 'check']" />
          </span>
          <span :class="$style.dropdown__content">
            JSON
          </span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useProcessStepsStore } from '@/stores/index.js';

export default {
  name: 'FormSelect',

  props: {
    storageMarker: { type: String, required: true },
    loading: { type: Boolean, default: false },
  },

  emits: ['resetMessages'],

  data() {
    return {
      defaultFormOptionName: 'default-form',
      jsonOptionName: 'json',
    };
  },

  computed: {
    ...mapState(useProcessesStore, ['forms', 'deliveries', 'processId']),
    ...mapState(useProcessStepsStore, ['steps', 'currentStep']),

    currentOptionValue: {
      get() {
        if (this.$attrs.value) return this.$attrs.value;

        const storageValue = localStorage.getItem(`${this.storageMarker}-${this.processId}`);
        if (storageValue === this.defaultFormOptionName || storageValue === this.jsonOptionName) {
          return storageValue;
        }
        if (this.forms) {
          const formFromStorageValue = this.forms.find((form) => form.formId === storageValue);
          return formFromStorageValue?.formId || this.defaultFormOptionName;
        }
        return this.defaultFormOptionName;
      },
      set(value) {
        this.$emit('input', value);
        localStorage.setItem(`${this.storageMarker}-${this.processId}`, value);
        this.$emit('resetMessages');
      },
    },

    dropDownTitle() {
      if (this.currentOptionValue === this.jsonOptionName) return 'JSON';
      if (this.currentOptionValue === this.defaultFormOptionName) return 'Auto-generated form';
      const currentForm = this.forms?.find((form) => form.formId === this.currentOptionValue && form.name !== 'Default form');
      if (currentForm) return currentForm.name;
      return 'Auto-generated form';
    },

    formItems() {
      return this.forms?.filter((form) => form.bindingId);
    },

    readyStepIndex() {
      return this.steps.findIndex((step) => step.settings.type === 'ready-step');
    },

    isReadyStep() {
      return this.currentStep === this.readyStepIndex;
    },
  },

  beforeMount() {
    if (!this.$attrs.value) {
      this.$emit('input', this.currentOptionValue);
    }
  },

  beforeDestroy() {
    this.$emit('input', '');
  },

  methods: {
    ...mapActions(useProcessesStore, ['readDeliveries']),
    ...mapActions(useProcessStepsStore, ['selectStep', 'changeStep']),

    goToFormList() {
      if (this.$route.name !== 'DocumentsProcessEdit') {
        this.$router.push({ name: 'DocumentsProcessEdit', params: { processId: this.processId } }).then(() => {
          const unwatch = this.$watch('deliveries', () => {
            const stepIndex = this.readyStepIndex;
            this.changeStep(stepIndex);
            unwatch();
          });
        });
      } else {
        const stepIndex = this.readyStepIndex;
        if (stepIndex !== -1) {
          this.selectStep({ stepIndex });
        }
      }
    },
  },
};
</script>
<style lang="scss" module>
.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  margin-right: 6px;

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__title {
    padding: 20px 0;
    margin: -20px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    padding-left: 14px;
  }

  &__item {
    display: flex;
    align-items: flex-end;
    padding: 8px 16px;
  }

  &__content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:first-child {
      margin-left: 24px;
    }
  }

  &__marker-current {
    margin-right: 7px;
  }

  &__breakline {
    height: 1px;
    background-color: #DBDBDB;
    margin: 8px 10px;
  }

  :global(.dropdown) {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 260px;
  }

  :global(.dropdown-content) {
    padding-bottom: 6px;
  }

  :global(.dropdown-menu) {
    width: 100%;
    right: 0;
    left: auto;
    padding-top: 7px;
    border-radius: 2px;

    @media (max-width: 476px) {
      width: auto;
      max-width: calc(100vw - 60px);
    }
  }

  :global(.dropdown-trigger) {
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }

  a {
    &:global(.dropdown-item.is-active) {
      background-color: transparent;
      color: inherit;
    }
  }

  @media (max-width: 1140px) {
    position: relative;
  }
}
</style>
