<template>
  <div :class="$style.wrapper">
    <p
      v-if="formId === 'default-form'"
      :class="$style.description"
    >
      This form is generated based on your template tokens. You can adjust
      how it looks. Find more information in
      <a
        href="https://plumsail.com/docs/documents/v1.x/user-guide/processes/custom-testing-form.html"
        target="_blank"
        rel="noopener noreferrer"
      >the documentation</a>.
    </p>

    <div :class="$style.form">
      <div
        v-if="formLoading"
        class="fd-form-loading"
      />
      <div v-else>
        <div
          id="Plumsail-form"
          ref="forms"
          :class="$style.widget"
        >
          <div class="fd-form-loading" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useSettingsStore, useErrorsStore } from '@/stores/index.js';
import { ensureFormsScript } from '@/utils/index.js';

export default {
  name: 'FormStartProcess',

  props: {
    actionIndicator: { type: [String, null], default: null },
    loading: { type: Boolean, default: false },
    formId: { type: String, default: 'default-form' },
    mode: { type: String, default: 'First' },
  },

  emits: ['download', 'afterStart', 'setSuccess', 'update:ActionIndicator', 'update:mode', 'update:loading'],

  data() {
    return {
      fd: null,
      formLoading: false,
      submissionId: null,
      isSetBeforeSave: null,
      isNeedDownload: false,
      jobId: null,
      locationLink: null,
      isClosing: false,
    };
  },

  computed: {
    ...mapState(useSettingsStore, { profile: 'getProfile' }),
    ...mapState(useProcessesStore, ['forms', 'processId']),

    isLoading: {
      get() { return this.loading; },
      set(value) { this.$emit('update:loading', value); },
    },

    currentMode: {
      get() { return this.mode; },
      set(value) { this.$emit('update:mode', value); },
    },

    currentForm() {
      return this.forms?.find((form) => form.formId === this.formId && form.name !== 'Default form');
    },

    bindingId() {
      return this.currentForm?.bindingId || null;
    },

    isDownloadReady() {
      return Boolean(this.locationLink || this.jobId);
    },
  },
  watch: {
    formId() {
      this.formLoading = true;
      this.fd = null;
      this.getForm();
    },

    actionIndicator(value) {
      if (value === 'start') {
        this.submit(this.currentMode === 'Second');
      }
      this.$emit('update:actionIndicator', null);
    },
  },
  async created() {
    if (!this.loading && this.formId) {
      this.getForm();
    }
  },

  beforeDestroy() {
    this.isClosing = true;
  },

  methods: {
    ...mapActions(useProcessesStore, {
      getDefaultFormIds: 'getDefaultFormIds',
      startProcess: 'publicStartProcessAsync',
      readJobId: 'readJobIdFromSubmission',
    }),
    ...mapActions(useErrorsStore, ['setError']),

    async getFormUid() {
      if (this.currentForm) return this.formId;
      const {
        data: { productionGuid },
      } = await this.getDefaultFormIds({ processId: this.processId });

      return productionGuid;
    },

    async getForm() {
      const id = await this.getFormUid();
      this.formLoading = false;
      await ensureFormsScript();

      this.fd = await new Plumsail.Form('#Plumsail-form', id, {
        language: 'en-US',
        culture: 'en-US',
        theme: 'Documents',
      });
      if (this.fd) {
        this.fd.beforeCreate((data) => {
          if (data?.components?.['fd-submit-button']) {
            data.components['fd-submit-button'] = () => null;
          }

          this.fd._layout.css = `
            ${this.fd._layout.css}
            [class*="##submit##"] { display: none !important; }
            .fd-datatable-wrapper { width: 100% !important; }
          `;
          this.fd._showAlert = () => null;
        });

        this.fd.beforeRender(() => {
          this.formLoading = false;
        });

        this.fd.rendered(() => {
          this.submissionId = null;
          this.isSetBeforeSave = null;
          this.fd.clear();
          if (!this.bindingId) this.fd._beforeSave = [];
        });
        this.fd.saved(async () => {
          if (this.isNeedDownload) {
            try {
              if (this.submissionId) {
                // in case of custom form
                let count = 0;
                while (count < 15) {
                  try {
                    count += 1;
                    // eslint-disable-next-line no-await-in-loop
                    const isJobIdReceived = await new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        const { data, error } = await this.readJobId({
                          processId: this.processId,
                          submissionId: this.submissionId,
                        });

                        if (error) {
                          reject(new Error(error.message || 'Error in request jobId'));
                          return;
                        }

                        if (data) {
                          this.jobId = data;
                          resolve(true);
                        }
                        if (this.isClosing) resolve(true);
                        resolve(false);
                      }, 2000);
                    });

                    if (isJobIdReceived) break;
                    if (count === 15) throw new Error('Failed to download file because the number of jobId request attempts was exceeded.');
                  } catch (error) {
                    throw new Error(error.message || 'Failed to download file because jobId was not received.');
                  }
                }
              }

              if (this.isDownloadReady) {
                this.$emit('download', { jobId: this.jobId, location: this.locationLink });
              } else {
                throw new Error('Download data not received');
              }
            } catch (error) {
              this.setError(error);
              this.$emit('afterStart');
              this.isLoading = false;
            }
          } else {
            this.isLoading = false;
            this.$emit('afterStart');
            this.$emit('setSuccess');
          }
        });
      }
    },

    async submit(download = false) {
      this.isNeedDownload = download;
      try {
        this.isLoading = true;
        this.currentMode = !download ? 'First' : 'Second';

        if (!this.bindingId && !this.isSetBeforeSave) {
          // in case of default form
          this.fd.beforeSave(async (formData) => {
            try {
              const payload = formData;
              const { data, error } = await this.startProcess({
                id: this.processId,
                processData: payload,
              });

              if (error) {
                throw new Error(error.message);
              }
              if (!data) {
                throw new Error('Process start error');
              }

              if (!download) {
                this.$emit('setSuccess');
                this.$emit('afterStart');
              }

              if (!data.location) {
                if (data.message) {
                  throw new Error(data.message);
                }
                throw new TypeError('Wrong response type');
              }

              this.locationLink = data.location;
            } catch (error) {
              this.setError(error);
              this.$emit('afterStart');
              throw error;
            }
          });
          this.isSetBeforeSave = true;
        }

        if (this.bindingId && !this.isSetBeforeSave) {
          // in case of custom form
          this.fd.beforeSave(() => {
            this.submissionId = this.fd.submissionId;
          });

          this.isSetBeforeSave = true;
        }
        await this.fd.save();
      } catch (error) {
        if (error.name !== 'FormValidationError') {
          this.setError(error);
          this.$emit('afterStart');
        }
        this.isLoading = false;
        throw (error);
      }
    },
  },
};
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.form {
  flex: 1 1 auto;
  overflow: auto;

  &::-webkit-scrollbar {
  width: 10px;
  height: 10px;;
  }

  &::-webkit-scrollbar-track {
  background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
  border: 3px solid #fff;
  border-radius: 10px;
  background-color: #C0C0C0;
  }
}

.description {
  padding: 0 12px;
}
.widget {
  width: 100%;
}
</style>
