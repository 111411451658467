<template>
  <side-panel
    :name="sidePanelName"
    :additional-class="$style.wrapper"
    :custom-close-handler="closeHandler"
  >
    <template #header>
      <div
        v-if="isCurrentPanel"
        :class="$style.controls"
      >
        <double-button
          :loading="loading || !dataReady"
          :class="$style.controls__item"
          :success="isSuccess"
          success-message="Started"
          show-loader
          is-switched
          @primaryClick="start(true)"
          @secondaryClick="start"
        >
          <template #primary>
            <span>
              {{ isFirstMode ? firstModeName : secondModeName }}
            </span>
          </template>
          <template #secondary>
            <span>
              {{ isFirstMode ? secondModeName : firstModeName }}
            </span>
          </template>
        </double-button>

        <button
          v-if="isStartPanel"
          :class="[$style.controls__item, $style.adaptiveBtn, 'button is-white']"
          type="button"
          @click="showRunProcess"
        >
          <span :class="['icon', $style.adaptiveBtn__icon]">
            <font-awesome-icon :icon="['fal', 'clock-rotate-left']" />
          </span>
          <span :class="$style.adaptiveBtn__text">Process runs</span>
        </button>

        <form-select
          v-if="dataReady"
          v-model="option"
          :loading="loading"
          :storage-marker="`${storageMarker}-option`"
        />
        <button
          :class="[
            $style.controls__item,
            $style.controls__item_right,
            'button',
            pin ? 'is-light' : 'is-white',
          ]"
          :title="`${!pin ? 'Pin' : 'Unpin'} the form. When pinned, the form does not close after the process starts.`"
          type="button"
          @click="togglePinPanel"
        >
          <span class="icon">
            <font-awesome-icon :icon="[pin ? 'fas' : 'fal', 'thumbtack']" />
          </span>
        </button>
      </div>
    </template>
    <div
      v-if="isCurrentPanel && dataReady"
      :class="$style.preview"
    >
      <div
        style="overflow: auto"
        :class="$style.body"
      >
        <slot />
      </div>
      <div
        v-if="currentOption === 'json'"
        :class="$style.footer"
      >
        <button
          type="button"
          :class="[$style.button, 'button', 'is-white']"
          @click="$emit('formatJson')"
        >
          <span :class="$style.button__icon">
            <font-awesome-icon :icon="['fal', 'indent']" />
          </span>
          Format JSON
        </button>
      </div>
    </div>
    <div v-else-if="isCurrentPanel">
      <div class="fd-form-loading" />
    </div>
  </side-panel>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useSidePanelStore } from '@/stores/index.js';

import SidePanel from '@/components/sidePanel.vue';
import DoubleButton from '@/components/doubleButton.vue';
import FormSelect from './components/form-select.vue';

export default {
  name: 'FormPanel',

  components: { DoubleButton, FormSelect, SidePanel },

  props: {
    loading: { type: Boolean, default: false },
    sidePanelName: { type: String, required: true },
    firstModeName: { type: String, required: true },
    secondModeName: { type: String, required: true },
    currentOption: { type: String, required: true },
    storageMarker: { type: String, required: true },
    isStartPanel: { type: Boolean, default: false },
    currentMode: { type: String, default: 'First' },
    dataReady: { type: Boolean, default: true },
    isPinned: { type: Boolean, default: false },
    isSuccess: { type: Boolean, default: false },
  },

  emits: ['start', 'formatJson', 'cancel', 'update:currentMode', 'update:currentOption', 'update:isPinned'],

  computed: {
    ...mapState(useSidePanelStore, ['currentPanel']),
    ...mapState(useProcessesStore, ['processId']),
    mode: {
      get() {
        return this.currentMode;
      },
      set(value) {
        this.$emit('update:currentMode', value);
        window.localStorage.setItem(`${this.storageMarker}-mode`, value);
      },
    },

    pin: {
      get() {
        return this.isPinned;
      },
      set(value) {
        this.$emit('update:isPinned', value);
      },
    },

    option: {
      get() {
        return this.currentOption;
      },
      set(value) {
        this.$emit('update:currentOption', value);
        this.openedOption = value;
      },
    },

    isFirstMode() {
      return this.currentMode === 'First';
    },
    isCurrentPanel() {
      return this.sidePanelName === this.currentPanel;
    },
  },

  methods: {
    ...mapActions(useSidePanelStore, ['resetPanels']),

    start(isPrimaryClick) {
      if (!isPrimaryClick) {
        const createMode = this.isFirstMode ? 'Second' : 'First';
        this.mode = createMode;
      }
      this.$emit('start', isPrimaryClick);
    },

    showRunProcess() {
      this.resetPanels();
      if (this.$route.name !== 'DocumentsProcessSummary') {
        this.$router.push({ name: 'DocumentsProcess', params: { processId: this.processId } });
      }
    },

    closeHandler() {
      this.$emit('cancel');
    },

    togglePinPanel() {
      this.pin = !this.pin;
    },
  },
};
</script>

<style lang="scss" module>
.wrapper {
  min-width: 63.8vw;
}
.preview {
  max-width: 80vw;
  width: calc(100vw - 25px);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.controls {
  display: flex;

  &__item {
    &_right {
      margin-left: auto;
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.body {
  display: flex;
  flex: 1 1 100%;
  overflow: auto;

  > div {
    width: 100%;
  }

  :global(.content) {
    display: flex;
    flex-direction: column;

    :global(.CodeMirror) {
      flex: 1 1 auto;
    }
  }
}

.footer {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .button:not(:first-child) {
    margin-right: 0.5rem;
  }
}
.button {
  &_small {
    padding: 6px 14px 4px;
  }

  &__icon {
    margin-right: 10px;
  }
}

@media (min-width: 1500px) {
  .preview {
    max-width: 1200px;
  }
}

@media (max-width: 960px) {
  .preview {
    max-width: 768px;
  }
}

@media (max-width: 768px) {
  .preview {
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .adaptiveBtn {
    & &__icon:first-child:not(:last-child) {
      margin: 0;
    }

    &__text {
      display: none;
    }
  }
}
</style>
