var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",class:_vm.$style.wrapper},[_c('button',{class:[
      'button is-primary',
      _vm.$style.active,
      _vm.$style.button,
      _vm.optionalBtnClass,
      { 'is-loading': _vm.showLoader && _vm.loading },
      { 'is-success': _vm.success },
      { [_vm.$style.success]: _vm.success },
    ],attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.primaryClick}},[(_vm.success)?_c('span',{class:_vm.$style.success_message},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'check']}}),_vm._v(" "+_vm._s(_vm.successMessage)+" ")],1):_c('span',{ref:"primary"},[_vm._t("primary")],2)]),_c('b-dropdown',{attrs:{"position":_vm.dropPosition,"mobile-modal":false},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('button',{class:[
          'button is-primary',
          _vm.$style.trigger,
          _vm.$style.button,
          _vm.optionalTriggerClass,
          { 'is-success': _vm.success },
        ],attrs:{"type":"button","disabled":_vm.loading}},[_c('span',{staticClass:"icon is-small"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'chevron-down']}})],1)])]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":_vm.secondaryClick}},[_c('span',{class:_vm.$style.secondary},[_c('span',{ref:"secondary"},[_vm._t("secondary")],2)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }