<template>
  <div
    ref="wrapper"
    :class="$style.wrapper"
  >
    <button
      type="button"
      :disabled="loading"
      :class="[
        'button is-primary',
        $style.active,
        $style.button,
        optionalBtnClass,
        { 'is-loading': showLoader && loading },
        { 'is-success': success },
        { [$style.success]: success },
      ]"
      @click="primaryClick"
    >
      <span
        v-if="success"
        :class="$style.success_message"
      >
        <font-awesome-icon :icon="['fal', 'check']" />
        {{ successMessage }}
      </span>
      <span
        v-else
        ref="primary"
      >
        <slot name="primary" />
      </span>
    </button>

    <b-dropdown
      :position="dropPosition"
      :mobile-modal="false"
    >
      <template #trigger>
        <button
          type="button"
          :disabled="loading"
          :class="[
            'button is-primary',
            $style.trigger,
            $style.button,
            optionalTriggerClass,
            { 'is-success': success },
          ]"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fal', 'chevron-down']" />
          </span>
        </button>
      </template>

      <b-dropdown-item @click="secondaryClick">
        <span :class="$style.secondary">
          <span ref="secondary">
            <slot name="secondary" />
          </span>
        </span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>

export default {
  name: 'DoubleButton',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    optionalTriggerClass: {
      type: [String, Object, Array],
      default: '',
    },
    optionalBtnClass: {
      type: [String, Object, Array],
      default: '',
    },
    dropPosition: {
      type: String,
      default: 'is-bottom-right',
    },
    successMessage: {
      type: String,
      default: 'Success',
    },
    success: {
      type: Boolean,
      default: false,
    },
    isSwitched: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      wrapperWidth: 'auto',
      widthFactor: 0,
    };
  },

  watch: {
    success(value) {
      if (value) {
        this.$refs.wrapper.style.width = '108px';
      } else {
        this.$refs.wrapper.style.width = this.wrapperWidth;
      }
    },
  },

  mounted() {
    if (this.isSwitched) {
      this.widthFactor = this.$refs.wrapper.offsetWidth - this.$refs.primary.offsetWidth;
      this.wrapperWidth = `${this.$refs.primary.offsetWidth + this.widthFactor}px`;
      this.$refs.wrapper.style.width = this.wrapperWidth;
    }
  },

  methods: {
    primaryClick() {
      this.$emit('primaryClick');
    },

    secondaryClick() {
      if (this.isSwitched) {
        this.wrapperWidth = `${this.$refs.secondary.offsetWidth + this.widthFactor}px`;
        this.$refs.wrapper.style.width = this.wrapperWidth;
      }
      this.$emit('secondaryClick');
    },
  },
};
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  align-self: flex-start;
  position: relative;
  transition: width .2s ease, padding .2s ease;

  .active {
    &.success {
      padding-left: 10px;

      &_message {
        animation: showSuccess 1s linear;
      }
      svg {
        margin-right: 6px;
      }

      & + :global(.dropdown) {
        max-width: 0;
        overflow: hidden;
      }
    }

    &:not(.success) {
      & > span {
        display: flex;
      }
      padding-right: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.button {
      flex: 1 1 auto;

      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }

  .trigger {
    padding-left: 9px;
    padding-right: 11px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &.button {
      margin-bottom: 0;
      font-size: 14px;
      height: 100%;
    }
  }
  .secondary {
    display: flex;
    align-items: flex-end;
    min-width: 100%;

    :global(.icon:first-child) {
      transform: translateY(1px);
      margin-left: -10px;
      margin-right: 3px;
    }
  }

  .button {
    transition: background-color .3s ease-in-out;

    &:focus:not(:active) {
      box-shadow: none;
    }
  }

  :global(.dropdown) {
    position: static;
    &:global(.is-top-right) {
      :global(.dropdown-menu) {
        top: -100%;
        bottom: auto;
      }
    }
  }

  :global(.dropdown-menu) {
    min-width: 100%;
    padding-top: 0;
    left: 0;
    border-radius: 2px;
  }

  :global(.dropdown-content) {
    position: absolute;
    background-color: #fff;
    min-width: 100%;
  }

  :global(.dropdown-item) {
    padding: 7px 16px;
    font-size: inherit;
  }
}

@keyframes showSuccess {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
