<template>
  <div :class="$style.wrapper">
    <p :class="$style.description">
      Paste here the JSON object that represents data for the template.
      Properties from this object should correspond to tokens used in your template.
    </p>
    <div :class="$style.content">
      <json-viewer
        v-model="$v.form.json.$model"
        placeholder="Place your JSON content here"
      />
    </div>
    <p
      v-if="$v.form.json.$error && !$v.form.json.json && $v.form.json.required"
      class="help is-danger"
    >
      Please check your JSON input. It seems to be invalid
    </p>

    <p
      v-if="$v.form.json.$error && !$v.form.json.required"
      class="help is-danger"
    >
      The JSON input is missing
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useProcessesStore, useTemplatesStore, useErrorsStore } from '@/stores/index.js';
import { required, jsonValidator } from '@/utils/validators.js';
import { AuthorizationException } from '@/utils/exceptions.js';
import JsonViewer from '@/components/json-viewer/index.vue';

export default {
  name: 'JsonStartProcess',
  components: { JsonViewer },

  props: {
    actionIndicator: { type: String, default: null },
    loading: { type: Boolean, default: false },
    mode: { type: String, default: 'First' },
  },

  emits: ['update:loading', 'update:mode', 'download', 'afterStart'],

  data() {
    return {
      message: '',
      form: { json: '' },
    };
  },
  computed: {
    ...mapState(useProcessesStore, ['processId']),
    ...mapState(useTemplatesStore, ['testTemplate']),

    isLoading: {
      get() { return this.loading; },
      set(value) { this.$emit('update:loading', value); },
    },

    currentMode: {
      get() { return this.mode; },
      set(value) { this.$emit('update:mode', value); },
    },
  },

  validations: { form: { json: { required, jsonValidator } } },

  watch: {
    actionIndicator(value) {
      if (value === 'start') {
        this.submit(this.currentMode === 'Second');
      }
      if (value === 'format') {
        this.formatInput();
      }
      this.$emit('update:actionIndicator', null);
    },
  },

  created() {
    this.form.json = this.testTemplate;
  },

  methods: {
    ...mapActions(useProcessesStore, { startProcess: 'publicStartProcessAsync' }),
    ...mapActions(useProcessesStore, ['updateTestProcess']),
    ...mapActions(useErrorsStore, ['setError']),

    formatInput() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        const string = JSON.parse(this.form.json);
        this.form.json = JSON.stringify(string, null, 4);
      }
    },

    async submit(download = false) {
      this.$v.form.$touch();
      if (this.$v.form.$error && !this.$v.form.json.json && this.$v.form.json.required) {
        return;
      }

      if (this.$v.form.json.$error && !this.$v.form.json.required) {
        return;
      }

      this.isLoading = true;
      this.currentMode = !download ? 'First' : 'Second';

      try {
        const { data, error } = await this.startProcess({
          id: this.processId,
          processData: JSON.parse(this.form.json),
        });

        if (!data || error) {
          throw new Error(error.message || 'Failed start process');
        }
        if (data?.code === 'AuthorizationException') {
          throw new AuthorizationException(data.message);
        }

        await this.updateTestProcess({
          id: this.processId,
          processData: { json: this.form.json },
        });

        if (download) {
          this.$emit('download', data);
        } else {
          this.$emit('setSuccess');
          this.$emit('afterStart');
          this.isLoading = false;
        }
      } catch (error) {
        this.setError(error);
        this.isLoading = false;
        this.$emit('afterStart');
      }
    },
  },
};
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.content {
  flex: 1 1 auto;
  overflow: auto;
  > :global(.CodeMirror) {
    height: 100%;
  }
}

.description {
  margin-bottom: 12px;
}
</style>
