<template>
  <form-panel
    first-mode-name="Start"
    second-mode-name="Start & Download"
    storage-marker="start-process"
    :side-panel-name="sidePanelName"
    :loading="isLoading || !shortUserId"
    :current-mode.sync="mode"
    :current-option.sync="currentOption"
    :is-pinned.sync="isPinned"
    :data-ready="dataReady"
    :is-success="success"
    is-start-panel
    @start="submit"
    @formatJson="actionIndicator = 'format'"
    @closeMessage="isShowMessage = false"
    @cancel="cancel"
  >
    <json-start-process
      v-if="processId && currentOption === 'json'"
      :action-indicator.sync="actionIndicator"
      :loading.sync="isLoading"
      :mode.sync="mode"
      @download="downloadAction"
      @setSuccess="setSuccess"
      @afterStart="afterStart"
    />
    <div v-else-if="processId && currentOption">
      <form-start-process
        :action-indicator.sync="actionIndicator"
        :loading.sync="isLoading"
        :mode.sync="mode"
        :form-id="currentOption"
        @download="downloadAction"
        @setSuccess="setSuccess"
        @afterStart="afterStart"
      />
    </div>
  </form-panel>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import {
  useProcessesStore,
  useSidePanelStore,
  useTemplatesStore,
  useErrorsStore,
} from '@/stores/index.js';

import FormStartProcess from './components/form-start-process.vue';
import JsonStartProcess from './components/json-start-process.vue';
import FormPanel from '@/views/documents/processes/components/form-panel/index.vue';

export default {
  name: 'StartProcessPanel',
  components: {
    FormPanel,
    FormStartProcess,
    JsonStartProcess,
  },

  props: {
    sidePanelName: { type: String, required: true },
    formId: { type: String, default: '' },
    processId: { type: String, default: '' },
  },

  data() {
    return {
      fd: null,
      mode: window.localStorage.getItem('start-process-mode') || 'First',
      isLoading: false,
      actionIndicator: null,
      option: '',
      dataReady: true,
      isPinned: false,
      success: false,
      successOffTimer: null,
    };
  },

  computed: {
    ...mapState(useProcessesStore, ['process', 'shortUserId']),
    ...mapState(useSidePanelStore, ['currentPanel']),

    currentOption: {
      get() { return this.option || this.formId; },
      set(value) { this.option = value; },
    },

    fileName() {
      return `${this.process.outputFileName}.${this.process.outputType.toLowerCase()}`;
    },

    isCurrentPanel() {
      return this.sidePanelName === this.currentPanel;
    },
  },

  watch: {
    async processId(value) {
      if (value) {
        this.dataReady = false;
        const id = this.processId;
        await Promise.all([
          this.readProcess({ id }),
          this.readTestTemplate({ processId: id }),
          this.readForms({ processId: id }),
        ]);
        this.dataReady = true;
      }
    },
  },

  mounted() {
    if (!this.shortUserId) {
      this.updateShortUserId();
    }
  },

  methods: {
    ...mapActions(useProcessesStore, ['readJob', 'readJobs', 'readForms', 'readProcess', 'updateShortUserId', 'getResultJob']),
    ...mapActions(useTemplatesStore, ['readTestTemplate']),
    ...mapActions(useSidePanelStore, ['closePanel']),
    ...mapActions(useErrorsStore, ['setError']),

    afterStart() {
      if (this.$route.name === 'DocumentsProcessSummary') {
        setTimeout(() => {
          this.readJobs({ processId: this.$route.params.processId });
        }, 500);
      }
      if (!this.isPinned) {
        this.success = false;
        if (this.successOffTimer) {
          clearTimeout(this.successOffTimer);
          this.successOffTimer = null;
        }
        this.cancel();
        this.closePanel();
      }
    },

    setSuccess() {
      this.success = true;
      this.successOffTimer = setTimeout(() => { this.success = false; }, 1500);
    },

    async downloadAction({ jobId, location }) {
      try {
        let count = 0;
        this.isLoading = true;
        while (count < 30) {
          try {
            count += 1;
            // eslint-disable-next-line no-await-in-loop
            const isFileDownload = await new Promise((resolve, reject) => {
              setTimeout(async () => {
                const { data, status, error } = await this.getResultJob({ jobId, location });

                const dataReady = status !== 202;
                const isUnexpectedError = (dataReady && !data?.link) || status === 'error';

                if (isUnexpectedError) {
                  reject(new Error(error?.message || 'Error in request job'));
                  return;
                }
                if (dataReady) {
                  await this.download(data.link);
                  this.afterStart();
                  resolve(true);
                }

                if (!this.isCurrentPanel) resolve(true);
                resolve(false);
              }, 1000);
            });
            if (isFileDownload) break;
          } catch (error) {
            throw new Error(error?.message || 'Download link not received');
          }
          if (count === 30) throw new Error('Number of request attempts exceeded');
        }
      } catch (error) {
        this.setError(error);
        this.afterStart();
      } finally {
        this.isLoading = false;
      }
    },

    async download(link) {
      try {
        await fetch(link)
          .then((resp) => resp.blob())
          .then((blob) => {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(new Blob([blob]));
            elem.download = this.getFileName(link);
            elem.target = '_blank';
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
            this.setSuccess();
          });
      } catch (error) {
        this.setError(error);
      }
    },

    submit() {
      this.actionIndicator = 'start';
    },

    cancel() {
      this.option = this.formId || '';
      this.isLoading = false;
      this.isPinned = false;
    },

    getFileName(link) {
      const url = new URL(decodeURI(link));
      return url.searchParams.get('rscd')
        ?.split(';')
        ?.find((element) => element.startsWith('filename'))
        ?.split('"')[1] || this.fileName;
    },
  },
};
</script>
